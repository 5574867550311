import { createApp } from 'vue'
import App from './index.vue'
import PrimeVue from 'primevue/config'
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.scss'
import * as ElIcons from '@element-plus/icons-vue'
import 'nprogress/nprogress.css'

const app = createApp(App)

for (const name in ElIcons) {
    app.component(name, ElIcons[name])
}

app.mount('#app')
app.use(PrimeVue)