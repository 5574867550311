import { ref, defineComponent } from "vue";
import Card from 'primevue/card';
import Image from 'primevue/image';
import Button from 'primevue/button';
import SlideVerify from "vue3-slide-verify";
import "vue3-slide-verify/dist/style.css";
import img1 from "@/assets/images/1.jpg";
import img2 from "@/assets/images/2.jpg";
import img3 from "@/assets/images/3.jpg";
import img4 from "@/assets/images/4.jpg";
import img5 from "@/assets/images/5.jpg";
import swal from 'sweetalert';
import { postAsk } from "@/assets/utils/utils";
import aes from "@/assets/utils/qyaes";
export default defineComponent({
  name: "index_page",
  components: {
    Card,
    Image,
    Button,
    SlideVerify
  },
  setup() {
    const block = ref();
    let usr = ref({});
    const images = ref([img1, img2, img3, img4, img5]);
    let isSuccess = ref(false);
    const onAgain = () => {
      // 检测到非人为操作
      isSuccess.value = false;
      block.value.refresh();
    };
    const onSuccess = times => {
      // 验证完成
      console.log(times);
      isSuccess.value = true;
    };
    const onFail = () => {
      // 验证不通过
      isSuccess.value = false;
      block.value.refresh();
    };
    const onRefresh = () => {
      isSuccess.value = false;
    };
    function login_button_clicked() {
      if (!usr.value.acc) {
        swal('错误', '请输入用户账户!', 'error');
        return;
      }
      if (!usr.value.pwd) {
        swal('错误', '请输入用户密码!', 'error');
        return;
      }
      if (!isSuccess.value) {
        swal('错误', '请验证!', 'error');
        return;
      }
      postAsk('/marketweb_api/login', usr.value).then(response => {
        let res = aes.decrypt2json(response.data.value, response.data.size);
        if (res.res === 1) {
          swal('提示', res.desc, 'success');
          let access_token = res['access_token'];
          let token_type = res['token_type'];
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('token_type', token_type);
          document.location.href = 'major.html';
        } else {
          swal('错误', res.desc, 'error');
          usr.value.acc = null;
          usr.value.pwd = null;
          block.value.refresh();
        }
      });
    }
    return {
      block,
      usr,
      images,
      text: "向右滑动->",
      onAgain,
      onSuccess,
      onFail,
      onRefresh,
      login_button_clicked
    };
  }
});