import axios from 'axios'
import swal from 'sweetalert'
import aes from "@/assets/utils/qyaes"
import NProgress from 'nprogress'


const config = {
  baseURL: '',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
}

// 不携带令牌的post请求
const postAsk = (url, data) => {
  NProgress.start()
  // 对 data 进行加密
  let txt = JSON.stringify(data)
  let enc = aes.encrypt(txt)
  let dat = {value: enc, size: txt.length}

  // eslint-disable-next-line
  const promise = new Promise((resolve, reject) => {
    axios.post(
        url, dat, config
    ).then((response) => {
      NProgress.done()
      resolve(response)
    }).catch((error) => {
      NProgress.done()
      console.log(error)
      // 对error中的数据进行处理
      let status = error.response.status
      let txt = errorToString(error)
      swal(status.toString(), txt, 'error')
      // reject(error)
    })
  })

  return promise
}

// 携带令牌的post请求
const postRequest = (url, data) => {
  NProgress.start()
  // eslint-disable-next-line
  const promise = new Promise((resolve, reject) => {
    // config.headers['Authorization'] = localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
    data.Authorization = localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
    // 对 data 进行加密
    let txt = JSON.stringify(data)
    let enc = aes.encrypt(txt)
    let dat = {value: enc, size: txt.length}
    axios.post(
      url, dat, config
    ).then((response) => {
      NProgress.done()
      resolve(response)
    }).catch((error) => {
      NProgress.done()
      console.log(error)
      // 对error中的数据进行处理
      let status = error.response.status
      let txt = errorToString(error)
      // 弹出错误信息
      if (status === 401) {
        swal({
          title: status.toString(),
          text: txt,
          icon: 'error',
          button: "确定",
        }).then((value) => {
          console.log(value)
          window.top.location.href = '/index.html'
        })
      } else {
        swal(status.toString(), txt, 'error')
      }
      // reject(error)
    })
  })

  return promise
}

function errorToString (error) {
  let text = ''
  if (error.response.data) {
    if (error.response.data instanceof Object)
      text = JSON.stringify(error.response.data)
    else
      text = error.response.data
  }
  return text
}

// 日期时间处理
function dateToString (date, format) {
  var moment = require('moment')
  return moment(date).format(format)
}

function stringToDate (sDate, format) {
  var moment = require('moment')
  return moment(sDate, format).toDate()
}

// 获取url参数
function findQueryString (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  // search 查询？后面的参数，并匹配正则
  var r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return unescape(r[2])
  }
  return null
}

export {axios, postAsk, postRequest, dateToString, stringToDate, findQueryString}
