import CryptoJS from 'crypto-js';

// 这是密钥，非常重要，可以是后台获取，或者是前后台约定好，注意保护
const key = CryptoJS.enc.Utf8.parse('zhqyczzxyyyq1018');
const iv = CryptoJS.enc.Utf8.parse('47FC4124CF94E070');

/**
 * 解密
 * @param {String} word 解密内容
 * @returns
 */
function decrypt(word) {
    let base64 = CryptoJS.enc.Base64.parse(word);
    let src = CryptoJS.enc.Base64.stringify(base64);

    let decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });

    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

/**
 * 加密
 * @param {String} word 加密内容
 * @returns
 */
function encrypt(word) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

function decrypt2json(word, size) {
    let s = decrypt(word)
    return (JSON.parse(s.substring(0, size)))
}

export default { decrypt, encrypt, decrypt2json };


